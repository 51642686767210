//  Primary color variants
// This color should be displayed most frequently and be used for important actions
@ocean000: #182a37; // white text
@ocean100: #0c4756; // white text
@ocean200: #04677b; // white text
@ocean300: #017f98; // white text
@ocean400: #36afcc; // dark text
@ocean500: #5dc9e3; // dark text
@ocean600: #90dff3; // dark text
@ocean700: #cff3fe; // dark text
@ocean800: #eff9fc; // dark text
@ocean900: #fbfdfe; // dark text

// Gray color variants
// Neutral color for structural UI elements.
@gray000: #2d3842; // white text
@gray100: #3c4955; // white text
@gray200: #415261; // white text
@gray300: #677581; // white text
@gray400: #8d98aa; // dark text
@gray500: #aeb9cb; // dark text
@gray600: #c8d0e0; // dark text
@gray700: #e0e4eb; // dark text
@gray800: #ebeff5; // dark text
@gray900: #f5f7fa; // dark text

// Success color variants
// This color should be used to show positive feedback or status.
@green000: #044f22; // white text
@green100: #196d3a; // white text
@green200: #2a844e; // white text
@green300: #65c089; // dark text
@green400: #a3e1bc; // dark text
@green500: #effff5; // dark text

// Warning color variants
// This color should be used to show warning feedback or status.
@yellow000: #fbcf32; // dark text
@yellow100: #fcd754; // dark text
@yellow200: #fddf76; // dark text
@yellow300: #fde797; // dark text
@yellow400: #feefb9; // dark text
@yellow500: #fff7db; // dark text

// Error color variants
// This color should be used to show negative feedback or status.
@red000: #912116; // white text
@red100: #ba3125; // white text
@red200: #cc493e; // white text
@red300: #ff8c85; // dark text
@red400: #ffcccf; // dark text
@red500: #ffebed; // dark text

// Orange color variants
// This color should be applied sparingly to highlight information or add personality
@orange000: #fe9d00; // dark text
@orange100: #fdb137; // dark text
@orange200: #ffc76c; // dark text
@orange300: #ffd591; // dark text
@orange400: #ffe3b6; // dark text
@orange500: #fff4e1; // dark text

// Purple color variants
// This color should be applied sparingly to highlight information or add personality
@purple000: #6e01a5; // white text
@purple100: #8a03cd; // white text
@purple200: #aa00ff; // white text
@purple300: #e87cfe; // dark text
@purple400: #f3baff; // dark text
@purple500: #fcedff; // dark text

// Utility colors
@black: #000000; // For convenience // only used in storybook -- map to gray200 if normal text, gray000 if against color bg
@white: #ffffff; // For convenience

// AI Colors
@aiBlue: #3579f6;
@aiWhite: #eee;
@aiGray: #444;
@aiPlum: #5151a0;
@aiViolet: #800080;

//Other
@hover-time: 130ms;
@transition: @hover-time ease-out;

// For things that have ocean backgrounds, like checkboxes
@focus-shadow: fadein(@ocean300, 90%) 0 1px 6px 2px;

// var(--new-focus-shadow): 0 1px 6px 4px highlight;

:export {
    ocean000: @ocean000;
    ocean100: @ocean100;
    ocean200: @ocean200;
    ocean300: @ocean300;
    ocean400: @ocean400;
    ocean500: @ocean500;
    ocean600: @ocean600;
    ocean700: @ocean700;
    ocean800: @ocean800;
    ocean900: @ocean900;
    gray000: @gray000;
    gray100: @gray100;
    gray200: @gray200;
    gray300: @gray300;
    gray400: @gray400;
    gray500: @gray500;
    gray600: @gray600;
    gray700: @gray700;
    gray800: @gray800;
    gray900: @gray900;
    green000: @green000;
    green100: @green100;
    green200: @green200;
    green300: @green300;
    green400: @green400;
    green500: @green500;
    yellow000: @yellow000;
    yellow100: @yellow100;
    yellow200: @yellow200;
    yellow300: @yellow300;
    yellow400: @yellow400;
    yellow500: @yellow500;
    red000: @red000;
    red100: @red100;
    red200: @red200;
    red300: @red300;
    red400: @red400;
    red500: @red500;
    orange000: @orange000;
    orange100: @orange100;
    orange200: @orange200;
    orange300: @orange300;
    orange400: @orange400;
    orange500: @orange500;
    purple000: @purple000;
    purple100: @purple100;
    purple200: @purple200;
    purple300: @purple300;
    purple400: @purple400;
    purple500: @purple500;
    black: @black;
    white: @white;
    aiBlue: @aiBlue;
    aiWhite: @aiWhite;
    aiGray: @aiGray;
    aiPlum: @aiPlum;
    aiViolet: @aiViolet;
}

:root {
    --ocean000: @ocean000;
    --ocean100: @ocean100;
    --ocean200: @ocean200;
    --ocean300: @ocean300;
    --ocean400: @ocean400;
    --ocean500: @ocean500;
    --ocean600: @ocean600;
    --ocean700: @ocean700;
    --ocean800: @ocean800;
    --ocean900: @ocean900;
    --gray000: @gray000;
    --gray100: @gray100;
    --gray200: @gray200;
    --gray300: @gray300;
    --gray400: @gray400;
    --gray500: @gray500;
    --gray600: @gray600;
    --gray700: @gray700;
    --gray800: @gray800;
    --gray900: @gray900;
    --green000: @green000;
    --green100: @green100;
    --green200: @green200;
    --green300: @green300;
    --green400: @green400;
    --green500: @green500;
    --yellow000: @yellow000;
    --yellow100: @yellow100;
    --yellow200: @yellow200;
    --yellow300: @yellow300;
    --yellow400: @yellow400;
    --yellow500: @yellow500;
    --red000: @red000;
    --red100: @red100;
    --red200: @red200;
    --red300: @red300;
    --red400: @red400;
    --red500: @red500;
    --orange000: @orange000;
    --orange100: @orange100;
    --orange200: @orange200;
    --orange300: @orange300;
    --orange400: @orange400;
    --orange500: @orange500;
    --purple000: @purple000;
    --purple100: @purple100;
    --purple200: @purple200;
    --purple300: @purple300;
    --purple400: @purple400;
    --purple500: @purple500;
    --black: @black;
    --white: @white;
    --aiBlue: @aiBlue;
    --aiWhite: @aiWhite;
    --aiGray: @aiGray;
    --aiPlum: @aiPlum;
    --aiViolet: @aiViolet;
    // using highlight allows users to override easier with their system colors
    --new-focus-shadow: 0 1px 6px 4px @ocean400;
}
