@import (reference) '~$HVUI/shared.module.less';
@import (reference) '~$HVUI/colors.module.less';

.header {
    border-bottom: 1px solid @gray500;
    justify-content: center;
    display: flex;
    background: @ocean000;
    padding-top: env(safe-area-inset-top);
}

.logo {
    display: block;
    width: 200px;
    align-self: center;
    margin: 1.5em 0;
    @media @sm {
        margin: 2em 0;
    }
}
