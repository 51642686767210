@import (reference) '~$HVUI/colors.module.less';

@offset: 220;
@duration: 1.8s;
@strokewidth: 5px;
@bgstrokewidth: @strokewidth * 2;

// Inspired by:
// https://codepen.io/mrrocks/pen/EiplA

.spinner {
    animation: rotator @duration linear infinite;
    overflow: visible;
    margin: auto;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full {
    .center();

    min-height: 100vh;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.bg-path {
    stroke: @gray800;
    stroke-width: @strokewidth;
}

.path {
    animation: dash @duration ease-in-out infinite;
    stroke-dasharray: @offset;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: @strokewidth;
    stroke: @ocean500;
    transform-origin: center;
}

.stroke-path:extend(.path) {
    stroke: white;
    stroke-width: @bgstrokewidth;
}

@keyframes dash {
    0% {
        stroke-dashoffset: @offset;
    }

    50% {
        stroke-dashoffset: (@offset / 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: @offset;
        transform: rotate(360deg);
    }
}
