@import (reference) '~$HVUI/shared.module.less';

//Shared button properties

.narrow() {
    font-size: 0.8em;
    padding: 0.571425em 1.1425em; // 8px 16px at full size
}

.button {
    border: none; //removes the border on some elements
    cursor: pointer;
    display: inline-block;
    font-size: 0.875em;
    font-weight: 500;
    outline: 0;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: @transition;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    -webkit-appearance: none;
    appearance: none;
    white-space: normal;
    &:hover {
        color: white;
    }
}

//=== Primary variant
.button-primary:extend(.button) {
    background: @ocean300;
    border-radius: 2em;
    color: white;
    letter-spacing: 0.0625em;
    margin-bottom: 0;
    padding: 1.14285em 2.285em; //16px 32px at full size
    &:focus,
    &:hover {
        background: @ocean200;
        color: white;
    }
    &:active {
        // transform: translateY(0.1em);
        box-shadow: 0 0 1em @ocean300;
    }
    svg {
        fill: white;
        display: inline-block;
        margin-right: 0.33em;
        top: 1px;
    }
}

//primary button with half of standard padding
.button-primary-narrow:extend(.button-primary all) {
    .narrow();
}

//Reverse Primary

//=== Primary variant
.button-reverse:extend(.button) {
    background: white;
    border-radius: 2em;
    color: @ocean500;
    letter-spacing: 0.0625em;
    margin-bottom: 0;
    padding: 1.14285em 2.285em; //16px 32px at full size
    &:focus,
    &:hover {
        color: @ocean300;
    }
    &:active {
        box-shadow: 0 0 1em @ocean300;
    }
    svg {
        fill: white;
    }
}

//== Alert variation
.button-alert:extend(.button-primary) {
    background: @red200;
    appearance: none;
    &:focus,
    &:hover {
        background: @red000;
        color: darken(white, 5%);
    }
    &:active {
        box-shadow: 0 0 1em @red000;
    }
}

.button-alert-narrow:extend(.button-alert all) {
    .narrow();
}

//=== Disabled variations
.disabled() {
    cursor: not-allowed;
}

.button-primary-disabled,
.button-alert-disabled {
    &:extend(.button-primary);
    .disabled;
    color: @gray300;
    background: @gray800;
}

.button-primary-narrow-disabled,
.button-alert-narrow-disabled {
    &:extend(.button-primary-disabled);
    font-size: 0.8em;
    padding: 0.571425em 1.1425em; // 8px 16px at full size
}

.button-secondary:extend(.button) {
    background: none;
    color: @ocean300;
    font-size: 0.75em;
    letter-spacing: 0.07143em;
    text-decoration: underline;
    text-underline-offset: 2px;
    svg {
        fill: currentColor;
        margin-right: 0.67em;
        transition: @transition;
    }
    span {
        position: relative;
        padding: 0 0 0.25em;
    }
    &:focus,
    &:hover {
        color: @gray200;
        svg {
            margin-right: 0.6875em;
        }
    }
}

.button-secondary-warning:extend(.button-secondary all) {
    color: @red200;
    &:hover {
        color: @red200;
    }
}

.button-secondary-reverse:extend(.button-secondary all) {
    color: white;
    &:hover {
        color: white;
    }
}

.button-secondary-disabled,
.button-secondary-warning-disabled {
    &:extend(.button-secondary);
    .disabled;
    color: @gray400;
    &:hover {
        color: @gray400;
    }
}

// Loading spinner
.loading-primary,
.loading-primary-narrow,
.loading-alert,
.loading-alert-narrow {
    padding: 0.75em;
}

.loading-secondary {
    padding: 0.5em;
}
