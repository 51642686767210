@import (reference) '~$HVUI/colors.module.less';
.heart-white {
    fill: #fff;
}

.logotype-white {
    fill: #fff;
}

.heart-ocean {
    fill: @ocean500;
}

.heart-gray {
    fill: @gray100;
}

.logotype-ocean:extend(.heart-ocean) {
}

.heart-navy {
    fill: @gray200;
}

.logotype-navy:extend(.heart-navy) {
}

.heart-gray {
    fill: @gray300;
}

.logotype-gray:extend(.heart-gray) {
}

.heart-gray-lt {
    fill: @gray400;
}

.logotype-gray-lt:extend(.heart-gray-lt) {
}
