@import (reference) '~$HVUI/shared.module.less';
@import '~$HVUI/sizes.module.less';

html,
body,
input,
textarea,
button {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
}

*,
input,
textarea,
*:before,
*:after {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    min-height: 100% !important;
    padding: 0;
}

html {
    :focus-visible {
        outline-color: var(--gray500);
    }
}

a {
    color: @ocean300;
    fill: currentColor;
    overflow-wrap: break-word;
    &:hover,
    &:focus {
        color: @ocean200;
    }
    &:focus {
        box-shadow: @focus-shadow;
    }
}

h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

.text-center {
    text-align: center;
}

body,
.react-root {
    height: 100%;
}

[lang^='vi'] *,
[lang^='zh'] *,
[lang^='ko'] * {
    font-family: 'Arial' !important;

    strong {
        font-weight: bold !important; // "bolder" isn't correct here
    }
}

h1 {
    color: @gray200;
    font-size: 1.85em;
    letter-spacing: -1px;
    line-height: 1.3em;
    margin: 0 0 1em 0;

    @media @md {
        font-size: 2.5em;
    }
}

h2 {
    color: @gray200;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 1.375em;
    margin: 0;
}

h3 {
    color: @gray200;
    font-size: 1.75em;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 1.4545em;
    margin: 0;
}

p {
    font-size: 1.125em; //18px
    line-height: 1.556em;
}

button {
    cursor: pointer;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    // Specific to skip links
    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        white-space: normal;
        width: auto;
    }
}

[tabindex='-1'] {
    outline: none;
}

// TODO - change this classname to reflect color name change?
.text-navy {
    color: @gray200;
}

.text-gray-dk {
    color: @gray100;
}

.text-gray {
    color: @gray300;
}

.text-red {
    color: @red200;
}

.text-small {
    font-size: 0.875em; //14px
    line-height: 1.7143em;
}
