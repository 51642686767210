@import (reference) '~$HVUI/shared.module.less';
// **DO NOT** assign a width to the field here, or on the input
// Sizes should only be on parent elements in your
// page-specific component to preserve component flexibility
.field {
    text-align: left;
    position: relative;
    margin-top: 0.75em;
    [data-grid='true'] & {
        @media @md {
            margin-top: 0;
        }
    }
    &:first-child {
        margin-top: 0;
    }
}

.field-pw:extend(.field all) {
    margin-bottom: 3em;
}

// LABEL
.label {
    color: @gray200;
    display: block;
    font-size: 0.8125em; //13px
    font-weight: normal;
    letter-spacing: 0.33px;
    line-height: 1.25em;
    margin-bottom: 0.666666667em;
    text-transform: uppercase;
    font-weight: 500;
}

.right-label {
    float: right;
    font-style: italic;
    text-transform: lowercase;
}

.label-lowercase:extend(.label) {
    color: @gray100;
    text-transform: none;
    font-size: 1.1em;
    font-weight: 500;
}

//FIELD-LEVEL ERROR MESSAGE
.error:extend(.label) {
    color: @red200;
    margin-top: 0.666666667em;
    min-height: 1.25em; // same as line height to prevent skipping
    // @media @md {
    //     margin-top: 0.25rem;
    //     text-align: right;
    // }
}

.input-interior() {
    padding: 0.6667em 1.75em 0.6667em 1.3333em;
    font-family: inherit;
}

//INPUT
.input {
    .input-interior();
    appearance: none;
    background: white;
    border-radius: 6px; //Do not scale with size of field
    border: 2px solid @gray400;
    color: @gray200;
    display: block;
    display: inline-block;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.4444em;
    outline: none;
    position: relative;
    transition: border @transition;
    width: 100%;
    &:focus {
        border-color: @ocean500;
    }
    &:disabled {
        background: @gray800!important;
        cursor: not-allowed;
        pointer-events: none;
    }
    &::placeholder {
        color: @gray400; //This looks dark to me?
        font-weight: 300;
    }
}

.input-complete,
.input-error {
    &:extend(.input);
    background-position: right 10px top 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    border-right-width: 6px;
}

.input-complete {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojQkZENDcxO30KPC9zdHlsZT4KPHBhdGggaWQ9InN1Y2Nlc3MiIGNsYXNzPSJzdDAiIGQ9Ik02LjQsMTJsLTQtNGwxLjEtMS4xbDIuOSwyLjlsNi4xLTYuMWwxLjEsMS4xTDYuNCwxMnogTTgsMEMzLjYsMCwwLDMuNiwwLDgKCWMwLDIuMSwwLjgsNC4yLDIuMyw1LjdDMy44LDE1LjIsNS45LDE2LDgsMTZzNC4yLTAuOCw1LjctMi4zQzE1LjIsMTIuMiwxNiwxMC4xLDE2LDhzLTAuOC00LjItMi4zLTUuN0MxMi4yLDAuOCwxMC4xLDAsOCwwTDgsMHoiLz4KPC9zdmc+Cg==);
    border-right-color: @green300;
}

.input-error {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkVycm9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTYgMTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0UzNUQ1RDt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJlcnJvciIgY2xhc3M9InN0MCIgZD0iTTgsMEMzLjYsMCwwLDMuNiwwLDhzMy42LDgsOCw4czgtMy42LDgtOFMxMi40LDAsOCwwTDgsMHogTTkuMiw4LjFsMS43LDEuN2MwLjMsMC4zLDAuMywwLjgsMCwxLjIKCWwwLDBjLTAuMywwLjMtMC44LDAuMy0xLjIsMEw4LDkuM0g3LjlMNi4yLDExYy0wLjMsMC4zLTAuOCwwLjMtMS4yLDBsMCwwYy0wLjMtMC4zLTAuMy0wLjgsMC0xLjJsMS43LTEuN1Y4TDUsNi4yCglDNC43LDUuOSw0LjcsNS40LDUsNS4xbDAsMGMwLjMtMC4zLDAuOC0wLjMsMS4yLDBsMS43LDEuN0g4TDkuOCw1YzAuMy0wLjMsMC44LTAuMywxLjIsMGwwLDBjMC4zLDAuMywwLjMsMC44LDAsMS4yTDkuMyw3LjkKCUM5LjIsOCw5LjIsOCw5LjIsOC4xTDkuMiw4LjF6Ii8+Cjwvc3ZnPgo=);
    border-color: @red200;
}

.select-wrapper:extend(.input) {
    padding: 0;
    z-index: 1;
    &:after {
        background: fade(@gray800, 30%)
            url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzhkOTdhMDtmaWxsLXJ1bGU6ZXZlbm9kZDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkFydGJvYXJkIDE8L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEuNDEgMEw2IDQuMzMgMTAuNTkgMCAxMiAxLjMzIDYgNyAwIDEuMzMgMS40MSAweiIvPjwvc3ZnPg==)
            no-repeat center;
        background-size: 12px;
        border: 1px solid transparent;
        border-left-color: @gray800;
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        transition: @transition;
        width: 3.3334em;
        z-index: 0;
    }
}

.select-wrapper-narrow:extend(.select-wrapper all) {
    &:after {
        width: 2em;
    }
}

.select-wrapper-focus:extend(.select-wrapper all) {
    border-color: @ocean500;
    &:after {
        background: fade(@ocean500, 30%)
            url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzhkOTdhMDtmaWxsLXJ1bGU6ZXZlbm9kZDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkFydGJvYXJkIDE8L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEuNDEgMEw2IDQuMzMgMTAuNTkgMCAxMiAxLjMzIDYgNyAwIDEuMzMgMS40MSAweiIvPjwvc3ZnPg==)
            no-repeat center;
        background-size: 12px;
        border-left-color: @ocean500;
    }
}

.select-wrapper-narrow-focus:extend(.select-wrapper-focus all) {
    &:after {
        width: 2em;
    }
}

.select {
    .input-interior();
    padding-right: 3.3em;
    appearance: none;
    -webkit-appearance: none !important;
    background: transparent;
    border: 0;
    font-size: 1em;
    outline: none;
    position: relative;
    width: 100%;
    z-index: 2;
}

.select::-ms-expand {
    display: none;
}

.checkbase() {
    cursor: pointer;
    appearance: none;
    position: absolute;
}

.checkbox {
    .checkbase();
    font-size: inherit;
    line-height: normal;
    margin: 0;
    border: none;
    width: 1.5em;
    height: 1.5em;
    & + .label,
    & + .label-lowercase {
        font-weight: 500;
        display: block;
        margin-bottom: 0;
        cursor: pointer;
    }
    & + .label {
        margin-left: 2.75em;
        line-height: 1.8;
    }
    & + .label-lowercase {
        margin-left: 2.166667em;
    }
    & + .label-uppercase {
        margin-left: 2.166667em;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &:before {
        background: white;
        border-radius: 4px;
        border: 1px solid @gray400;
        content: '';
        display: block;
        height: 1.5em;
        width: 1.5em;
        position: absolute;
    }
    &:focus {
        outline: none;
        &:before {
            border: 2px solid @ocean500;
        }
    }
    &:checked {
        &:before {
            background: @ocean500
                url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTEuMiA4LjMiPjxzdHlsZT4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNNCA4LjNsLTQtNCAxLjEtMS4xTDQgNi4xIDEwLjEgMGwxLjEgMS4xeiIvPjwvc3ZnPg==)
                no-repeat center;
            background-size: 80%;
            border-color: @ocean500;
        }

        &:focus {
            &:before {
                border: 2px solid @ocean300;
            }
        }
    }
    &:disabled {
        &:before {
            background: @gray400;
            border-color: @gray800;
        }
    }
}

.checkbox-error:extend(.checkbox all) {
    font-size: inherit;
    &:before {
        border: 2px solid @red200;
    }
}

.radio {
    .checkbase();
    font-size: inherit;
    line-height: normal;
    margin: 0;
    border: none;
    width: 1.5em;
    height: 1.5em;
    & + .label,
    & + .label-lowercase {
        display: block;
        margin-bottom: 0;
    }
    & + .label {
        margin-left: 2.75em;
        line-height: 1.8;
    }
    & + .label-lowercase {
        margin-left: 2.166667em;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &:before {
        background: white;
        border-radius: 50%;
        border: 6px solid @gray400;
        content: '';
        display: block;
        height: 1.5em;
        width: 1.5em;
        position: absolute;
    }
    &:focus {
        outline: none;
        &:before {
            border: 2px solid @ocean500;
        }
    }
    &:checked {
        &:before {
            background: transparent;
            border: 1px solid @gray400;
            z-index: 1;
        }
        &:focus {
            &:before {
                border: 2px solid @ocean300;
            }
        }
        &:after {
            background: @ocean500;
            border-radius: 50%;
            border: 6px solid #fff;
            content: '';
            display: block;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
        }
    }
}

.radio-container + .radio-container {
    margin-top: 16px;
}

.switch {
    @swborder: 2px;
    @swduration: 200ms;
    .checkbase();
    line-height: normal;
    & + .label {
        display: inline-block;
        padding: 0 0 0 3.5em;
        line-height: 1.5em;
        position: relative;
        top: -0.5em;
        &:before,
        &:after {
            content: '';
            transition: @swduration all;
            position: absolute;
            cursor: pointer;
            display: block;
        }
        &:before {
            // Slider
            background: @gray400;
            border-radius: 1em;
            height: 1em;
            margin: 0.25em 0;
            transition: @swduration all;
            width: 3em;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:after {
            // Toggle / ball
            right: auto;
            transform: translate(0, 0);
            display: block;
            width: 1.6667em;
            height: 1.6667em;
            border-radius: 50%;
            background: #ffffff;
            left: 0;
            top: -1px;
            border: @gray400 @swborder solid;
        }
    }
    &:checked {
        & + .label {
            &:before {
                // Slider
                background: @ocean500;
            }
            &:after {
                //Toggle / ball
                transform: translate(82%, 0);
                border-color: @ocean500;
            }
        }
    }
}

//Password Field
.pw-wrapper {
    position: relative;
}

.meter-container {
    display: flex;
    align-items: center;
    margin: 0.5em 0;
}

.meter {
    flex: 0 1 70%;
    background: @gray800;
    border-radius: 6px;
    height: 0.5em;
    position: relative;
}

.meterSpan {
    background: @ocean500;
    border-radius: 6px;
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    border: 3px solid white;
    box-sizing: content-box;
    top: -3px; //adjusts for border
}

.meterText {
    color: @gray200;
    flex: 0 1 30%;
    // position: absolute;
    // right: 100%;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    width: 8em;
    top: -2px;
    display: block;
    padding-right: 1em;
}

.meterSpan-weak:extend(.meterSpan) {
    background: @red200;
}

.meterSpan-okay:extend(.meterSpan) {
    background: @yellow000;
}

.meterSpan-strong:extend(.meterSpan) {
    background: @green300;
}

.visibility-toggle {
    background: #fff;
    border: none;
    cursor: pointer;
    font-size: 0.75em; //Some whitespace so it doesn't get overrun by really long passwords
    margin: -1em;
    padding: 1em;
    position: absolute;
    text-transform: uppercase;
    right: 1.5em;
    top: 1.667em;

    &:focus {
        border: 2px solid @ocean500;
        border-radius: 6px;
        outline: none;
        right: calc(1.5em - 2px);
        top: calc(1.667em - 2px);
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

.check-field-multi-item {
    margin-top: 1em;
}

.check-field-multi-item:first-child {
    margin-top: 0;
}

.read-only {
    font-size: 1.125em;
    color: @gray200;
    margin: -0.25em 0 0.5em 1.3333em;
    left: 3px; //border offset
}

.help-text {
    font-style: italic;
    margin-top: 0.75em;
    display: inline-block;
}
