/* stylelint-disable property-no-unknown */
@_1px: 0.063rem;
@_2px: 0.125rem;
@_3px: 0.188rem;
@_4px: 0.25rem;
@_5px: 0.313rem;
@_6px: 0.375rem;
@_7px: 0.438rem;
@_8px: 0.5rem;
@_9px: 0.563rem;
@_10px: 0.625rem;
@_11px: 0.688rem;
@_12px: 0.75rem;
@_13px: 0.813rem;
@_14px: 0.875rem;
@_15px: 0.9375rem;
@_16px: 1rem;
@_17px: 1.0625rem;
@_18px: 1.125rem;
@_19px: 1.1875rem;
@_20px: 1.25rem;
@_21px: 1.3125rem;
@_22px: 1.375rem;
@_24px: 1.5rem;
@_26px: 1.625rem;
@_28px: 1.75rem;
@_30px: 1.875rem;
@_32px: 2rem;
@_34px: 2.125rem;
@_36px: 2.25rem;
@_38px: 2.375rem;
@_40px: 2.5rem;
@_42px: 2.625rem;
@_44px: 2.75rem;
@_46px: 2.875rem;
@_48px: 3rem;
@_50px: 3.125rem;
@_52px: 3.25rem;
@_54px: 3.375rem;
@_56px: 3.5rem;
@_58px: 3.625rem;
@_60px: 3.75rem;
@_62px: 3.875rem;
@_64px: 4rem;
@_100px: 6.25rem;
@_200px: 12.5rem;
@normal: 400;
@semi-bold: 600;
@bold: 700;

:export {
    _1px: @_1px;
    _2px: @_2px;
    _3px: @_3px;
    _4px: @_4px;
    _5px: @_5px;
    _6px: @_6px;
    _7px: @_7px;
    _8px: @_8px;
    _9px: @_9px;
    _10px: @_10px;
    _11px: @_11px;
    _12px: @_12px;
    _13px: @_13px;
    _14px: @_14px;
    _15px: @_15px;
    _16px: @_16px;
    _17px: @_17px;
    _18px: @_18px;
    _19px: @_19px;
    _20px: @_20px;
    _21px: @_21px;
    _22px: @_22px;
    _24px: @_24px;
    _26px: @_26px;
    _28px: @_28px;
    _30px: @_30px;
    _32px: @_32px;
    _34px: @_34px;
    _36px: @_36px;
    _38px: @_38px;
    _40px: @_40px;
    _42px: @_42px;
    _44px: @_44px;
    _46px: @_46px;
    _50px: @_50px;
    _52px: @_52px;
    _54px: @_54px;
    _56px: @_56px;
    _58px: @_58px;
    _60px: @_60px;
    _62px: @_62px;
    _64px: @_64px;
    _100px: @_100px;
    _200px: @_200px;
    normal: @normal;
    semi-bold: @semi-bold;
    bold: @bold;
}

:root {
    --1px: @_1px;
    --2px: @_2px;
    --3px: @_3px;
    --4px: @_4px;
    --5px: @_5px;
    --6px: @_6px;
    --7px: @_7px;
    --8px: @_8px;
    --9px: @_9px;
    --10px: @_10px;
    --11px: @_11px;
    --12px: @_12px;
    --13px: @_13px;
    --14px: @_14px;
    --15px: @_15px;
    --16px: @_16px;
    --17px: @_17px;
    --18px: @_18px;
    --19px: @_19px;
    --20px: @_20px;
    --21px: @_21px;
    --22px: @_22px;
    --24px: @_24px;
    --26px: @_26px;
    --28px: @_28px;
    --30px: @_30px;
    --32px: @_32px;
    --34px: @_34px;
    --36px: @_36px;
    --38px: @_38px;
    --40px: @_40px;
    --42px: @_42px;
    --44px: @_44px;
    --46px: @_46px;
    --50px: @_50px;
    --52px: @_52px;
    --54px: @_54px;
    --56px: @_56px;
    --58px: @_58px;
    --60px: @_60px;
    --62px: @_62px;
    --64px: @_64px;
    --100px: @_100px;
    --200px: @_200px;
    // font-weight sizes
    --normal: @normal;
    --semi-bold: @semi-bold;
    --bold: @bold;
}
