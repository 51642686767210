@import (reference) '~$HVUI/shared.module.less';

.alert {
    flex: 1;
    text-align: left;
    max-width: 100%;

    @media @xs {
        width: auto !important;
    }
}

.alert, .alert-slim {
    margin-bottom: 1rem;
}

.alert-slim {
    padding: 1rem;
}
