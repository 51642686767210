@import (reference) './colors.module.less';

// DEPRECATED -- Do not use
@little: ~'only screen and (max-width: 749px)';
@big: ~'only screen and (min-width: 750px)';

@xs: ~'only screen and (max-width: 575px)';
@sm: ~'only screen and (min-width: 576px)';
@md: ~'only screen and (min-width: 768px)';
@lg: ~'only screen and (min-width: 992px)';
@xl: ~'only screen and (min-width: 1280px)';
@xxl: ~'only screen and (min-width: 1660px)';

:global {
    // Don't know why this isn't getting pulled in from global.less -- backstop it here for now so it's not broken in CW
    .visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        // Specific to skip links
        &:active,
        &:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            white-space: normal;
            width: auto;
        }
    }
    // For a11y, sometimes buttons need to appear as links
    .button-link {
        background: none;
        border: none;
        display: inline;
        text-decoration: underline;
        color: var(--ocean300);
        margin: 0;
        padding: 0;
    }

    .link-as-button {
        background: var(--gray400);
        border: 1px solid transparent;
        border-radius: 2em;
        color: black;
        font-weight: 500;
        letter-spacing: 0.0625em;
        margin-bottom: 0;
        outline: 1px solid transparent;
        padding: 1.14285em 2.285em;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: black;
            text-decoration: none;
        }
        &.primary {
            background: var(--ocean500);
            transition: 0.2s ease background;
            &:hover {
                background: var(--ocean400);
            }
        }
    }
}

// extend only classes and mixins
// Use these in your apps as needed
.waves() {
    background: url('/static/img/_bkgd/waves-final-min.svg') no-repeat bottom,
        linear-gradient(to bottom, var(--white), var(--ocean800));
    background-size: 300%, auto;
    background-attachment: fixed, fixed;
    @media @md {
        background-size: auto;
    }
}

.container() {
    padding: 0 24px;
    @media @md {
        width: 990px;
        padding: 0;
        margin: 0 auto;
    }
}

.box-shadow() {
    box-shadow: 0 0 24px 0 rgba(139, 150, 160, 0.4);
}

.card {
    .box-shadow();
    border-radius: 6px;
    background: white;
    padding: 24px;
}
.bar {
    height: 3em;
    background-color: var(--gray200);
    display: flex;
    justify-content: space-between;

    a {
        &:focus {
            box-shadow: none;
        }
    }
}

// Automatically sets text color based on background color
.background-and-text(@color, @dark: #000, @light: #fff) {
    background: @color;
    color: contrast(@color, @dark, @light);
}

.tooltip() {
    .background-and-text(rgba(33, 33, 33));
    border-radius: 4px;
    color: var(--gray800);
    padding: 8px;
    z-index: 99999; // to appear above modals 😑
    position: absolute;

    > div {
        color: rgba(33, 33, 33);
    }
}

.narrow() {
    padding: 0.571425rem 1.1425rem; // 8px 16px at full size
}

// use to satisfy a11y requirements when need a button styled like a link
.link-button {
    text-decoration: underline;
    background: transparent;
    padding: 0;
    border: none;
    font-weight: var(--normal);
    cursor: pointer;
    color: var(--ocean300);
}

.link-button__uppercase {
    &:extend(.link-button);
    text-transform: uppercase;
}

.link-button:hover,
.link-button__uppercase:hover {
    color: var(--ocean200);
}

.link-button:focus-visible,
.link-button__uppercase:focus-visible {
    outline: 2px auto var(--gray500);
}
