@import (reference) '~$HVUI/shared.module.less';

.disclaimer {
    padding: 0 24px;
    @media @md {
        max-width: 780px;
        margin-bottom: 32px;
        position: relative;
        bottom: 0;
        left: 50%;
        margin-left: -390px;
    }
}
